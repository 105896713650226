import React from "react";
import styled from "styled-components";

const PackageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
`;

const PackagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const PackageDealTitle = styled.h3`
  align-items: flex-start;
  margin-left: 15px;
  color: white;
`;

const Package = styled.div`
  width: 22%;
  height: 300px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ color }) => color};

  @media (max-width: 768px) {
    width: 32%;
    height: 330px;
  }
`;

const PackageTitle = styled.p`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
  width: 100%;
  text-align: center;
  height: 15%;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const PackageList = styled.ul`
  padding: 0;
  margin: 0;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #000;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const PackageListItem = styled.li`
  font-size: 16px;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const PackagePrice = styled.p`
  font-size: 18px;
  margin-top: 5px;
  height: 10%;
  padding-top: 9px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const PackageDeals = () => {
  return (
    <PackageContainer>
      <PackageDealTitle>Package Deals</PackageDealTitle>
      <PackagesWrapper>
        <Package color="gray">
          <PackageTitle>Silver Package</PackageTitle>
          <PackageList>
            <PackageListItem>Date Itenerary</PackageListItem>
            <PackageListItem>Complimentary Message</PackageListItem>
            <PackageListItem>Extra Suggestion</PackageListItem>
          </PackageList>
          <PackagePrice>Price 150$</PackagePrice>
        </Package>
        <Package color="rgb(0, 135, 73)">
          <PackageTitle>Emerald Package</PackageTitle>
          <PackageList>
            <PackageListItem>Date Itenerary</PackageListItem>
            <PackageListItem>Complimentary Message</PackageListItem>
            <PackageListItem>Extra Suggestion</PackageListItem>
            <PackageListItem>Specific time Pickup/Dropoff</PackageListItem>
          </PackageList>
          <PackagePrice>Price 220$</PackagePrice>
        </Package>
        <Package color="gold">
          <PackageTitle>Gold Package</PackageTitle>
          <PackageList>
            <PackageListItem>Date Itenerary</PackageListItem>
            <PackageListItem>Complimentary Message</PackageListItem>
            <PackageListItem>Extra Suggestion</PackageListItem>
            <PackageListItem>Specific time Pickup/Dropoff</PackageListItem>
            <PackageListItem>1 Extra Rideshare included</PackageListItem>
            <PackageListItem>Exlusive Resturant Bookings</PackageListItem>
          </PackageList>
          <PackagePrice>Price 300$</PackagePrice>
        </Package>
      </PackagesWrapper>
    </PackageContainer>
  );
};

export default PackageDeals;
