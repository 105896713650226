import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Styled components
const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
`;

const Button = styled.button`
  width: 90px;
  height: 30px;
  border-radius: 20px;
  position: absolute;
  left: 10%; /* Adjust to move the button to the left */
  transform: translateX(-50%);
`;

const CalendarMenu = styled.div`
  position: absolute;
  top: 60px; /* Adjust based on the height of the container */
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const BookNow = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <Container>
      <Button onClick={toggleCalendar}>Book Now</Button>
      {showCalendar && (
        <CalendarMenu>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
          />
        </CalendarMenu>
      )}
    </Container>
  );
};

export default BookNow;
