import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";
import logo from "../assets/logo.png";

export const PageFooter = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span>© Copyright 2024 Perfect Vibes LLC.</span>
            </p>
          </div>
        </div>
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <img
              className="page-footer-brand__logo"
              src={logo}
              alt="Perfect Vibes"
              width="150"
              height="100"
            />
            <PageFooterHyperlink path="https://google.com/">
              <p>Instagram</p>
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
