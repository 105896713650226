import React from "react";
import styled from "styled-components";

const HeroLeftContainer = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  padding-horizontal: 10%;
`;

const HeroText = styled.p`
  font-size: 33px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const HeroLeft = () => {
  return (
    <HeroLeftContainer>
      <HeroText>
        Looking for the <strong>Perfect</strong> date itenerary?
      </HeroText>
    </HeroLeftContainer>
  );
};

export default HeroLeft;
