import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import HeroImage1 from "../../assets/HeroImg1.png";
import HeroImage2 from "../../assets/HeroImg2.png";
import HeroImage3 from "../../assets/HeroImg3.png";
import HeroImage4 from "../../assets/HeroImg4.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const HeroRightContainer = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ImagePair = styled.div`
  display: flex;
  position: absolute;
  animation: ${({ fade }) => (fade ? fadeOut : fadeIn)} 1s linear;
  opacity: ${({ fade }) => (fade ? 0 : 1)};
  transition: opacity 1s linear;
`;

const StyledImage = styled.img`
  height: 225px;
  width: 150px;
  margin: 0 10px;

  @media (max-width: 768px) {
    height: 180px;
    width: 110px;
  }
`;

export const HeroRight = () => {
  const [showFirstPair, setShowFirstPair] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstPair((prev) => !prev);
    }, 10000); // Change pair every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroRightContainer>
      <ImagePair fade={!showFirstPair}>
        <StyledImage src={HeroImage1} alt="Hero 1" />
        <StyledImage src={HeroImage2} alt="Hero 2" />
      </ImagePair>
      <ImagePair fade={showFirstPair}>
        <StyledImage src={HeroImage3} alt="Hero 3" />
        <StyledImage src={HeroImage4} alt="Hero 4" />
      </ImagePair>
    </HeroRightContainer>
  );
};

export default HeroRight;
