import React from "react";
import styled from "styled-components";
import HeroRight from "./hero-section/HeroRight";
import HeroLeft from "./hero-section/HeroLeft";

const HeroBannerContainer = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
`;

export const HeroBanner = () => {
  return (
    <HeroBannerContainer>
      <HeroLeft />
      <HeroRight />
    </HeroBannerContainer>
  );
};

export default HeroBanner;
