import React from "react";
import { HeroBanner } from "../components/hero-banner";
import { PackageDeals } from "../components/package-deals";
import { PageLayout } from "../components/page-layout";
import BookNow from "../components/Home/BookNow";

export const HomePage = () => (
  <PageLayout>
    <HeroBanner />
    <BookNow />
    <PackageDeals />
  </PageLayout>
);
