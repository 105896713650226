import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo.png"; // Adjust the path according to your project structure

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={logo} // Set the src to the imported logo
          alt="Perfect Lives Logo"
          width="250"
          height="246"
        />
      </NavLink>
    </div>
  );
};

export default NavBarBrand;
